.header {
  p {
    font-size: 1rem;
  }

  &__inner {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;

    @media (max-width: 720px) {
      justify-content: space-between;
    }
  }

  &__phone {
    display: flex;
    margin-left: auto;
    background: url(../../images/icon-phone.svg) left no-repeat;
  }

  &__address {
    background: url(../../images/icon-address.svg) left no-repeat;
  }

  &__phone,
  &__address {
    padding-left: 26px;
    margin-right: 50px;
    height: 20px;

    @media (max-width: 720px) {
      display: none;
    }
  }

  &__link {
    font-weight: normal;
    font-size: 1rem;
  }

  &__login {
    margin-right: 50px;
    display: flex;

    &__icon {
      margin-right: 8px;
    }

    @media (max-width: 575px) {
      display: none;
    }
  }
}

.burger-menu {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    span {
      &:nth-child(1),
      &:nth-child(3) {
        width: 20px;
      }

      &:nth-child(2) {
        width: 15px;
      }
    }
  }

  &__icon {
    height: 12px;
    width: 22px;
    border-radius: 30px;
    position: relative;
    margin-right: 8px;
  }

  span {
    display: block;
    background: $orange;
    height: 2px;
    width: 18px;
    position: absolute;
    left: 0;
    transition: all 0.2s ease-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }
}

.main-nav {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 456px;
  background: $orange;
  opacity: 1;
  transition: all 0.2s ease-out;
  z-index: 999;

  &.courses-navigator {
    left: 0;
  }

  @media (max-width: 575px) {
    width: 100%;
  }

  &__close-btn {
    background: url(../../images/icon-close.svg) no-repeat;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 47px;
    left: 47px;
    cursor: pointer;

    @media (max-width: 575px) {
      left: unset;
      right: 47px;
    }

    &.courses-navigator__close-btn {
      right: 47px;
      left: unset;
    }
  }

  &__menu {
    margin-top: 175px;
    margin-left: 47px;

    li {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: $white;
        font-size: 1.125rem;
        font-weight: 300;
        line-height: 120%;
        max-width: 309px;
        position: relative;
        padding-left: 28px;
        transition: all 0.2s ease-in;

        &:hover {
          font-weight: bold;
          position: relative;
          background: url(../../images/icon-arrow-right.svg) left center
            no-repeat;
        }
      }
    }
  }

  .contact-mobile {
    display: none;

    @media (max-width: 720px) {
      display: block;
      position: absolute;
      bottom: 80px;
    }

    .header__phone {
      margin-bottom: 24px;
      background: url(../../images/icon-phone_white.svg) left no-repeat;
    }

    .header__address {
      background: url(../../images/icon-address_white.svg) left no-repeat;
    }

    .header__phone,
    .header__address {
      display: block;
      margin-left: 28px;

      a {
        color: $white;
      }
    }
  }
}

.courses-navigator-toggle {
  position: absolute;
  right: -156px;
  top: 250px;
  background: url(../../img/courses-navigator-btn.svg) no-repeat;
  height: 36px;
  width: 277px;
  cursor: pointer;

  transform: rotate(90deg);
  color: $white;
  font-size: 1rem;
  line-height: 36px;
  text-align: center;

  @media screen and (max-width: 576px) {
    right: -150px;
    height: 24px;
    line-height: 24px;
  }
}

%close {
  transform: translateX(456px);
  transition: all 0.2s ease-out;
  // opacity: 0;
}

.close {
  @extend %close;
}

.courses-navigator__close {
  @extend %close;
  transform: translateX(-100%);
}
