.form {
  &__input {
    width: 865px;
    border: 1px solid $line-color;
    padding: 12px;
    margin-bottom: 6px;
    text-align: left;
  }

  &__paragraph {
    display: block;
    color: $text-color;
    padding: 18px 0 6px;
    font-size: 14px;
    line-height: 120%;
  }

  &__textarea {
    height: 102px;
    resize: vertical;
    overflow: auto;
    padding-right: 26px;

    &::-webkit-resizer {
      background-size: contain;
      background: url('../../img/resize-icon.svg') no-repeat;
    }
  }

  &__textarea ~ &__block-text {
    margin-top: -14px;
    margin-bottom: 55px;
    padding: 0;
  }

  &__block-text {
    width: 865px;
    display: flex;
    justify-content: space-between;
    padding: 20px 0 6px;

    .form__paragraph {
      padding: 0;
    }
  }

  &__block-el {
    position: relative;
    max-width: 865px;
    width: 100%;
    margin-bottom: 12px;

    .form__link {
      position: absolute;
      right: 0;
      top: 17px;
      padding: 0;
    }
  }

  &__link_delete {
    color: $color-danger;
    font-size: 14px;
    line-height: 120%;
  }

  &__link_add,
  &__link_edit {
    color: $accent-text-color;
    margin-right: 15px;
  }

  &__item {
    margin-top: 20px;
    padding: 20px 26px 20px 20px;
    width: 865px;
    border: 1px solid $line-color;
    text-align: left;

    &_add {
      display: block;
      padding: 35px 0;
      text-align: center;
      border: 1px dashed $btn-bg-color;
    }
  }

  &__question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__link_add_answer {
    margin-top: 20px;
    display: block;
  }

  &__block-text_answer {
    width: 833px;
    transition: all 0.5s linear;
  }

  &__answer {
    .form__input {
      width: 833px;
    }
  }

  &__radio {
    padding-left: 10px;
  }
}
.search-block {
  padding-bottom: 40px;
}
