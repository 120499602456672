@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Heavy.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-LightItalic.woff2') format('woff2'),
    url('../fonts/SFProDisplay-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Light.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-RegularItalic.woff2') format('woff2'),
    url('../fonts/SFProDisplay-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Semibold.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
