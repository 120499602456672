.radio {
  display: inline;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;
  }

  input + label {
    position: relative;
    user-select: none;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 22px;
      height: 22px;
      border: 1px solid $line-color;
      border-radius: 50%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 16px;
      height: 16px;
      background-color: $color-success;
      border-radius: 50%;
      opacity: 0;
    }
  }

  input:checked + label:after {
    opacity: 1;
  }

  input:checked + label:before {
    border: 1px solid $color-success;
  }
}
