.persons-list {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
}

.person {
  display: flex;
  width: 50%;
  margin-bottom: 20px;

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 575px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
  }

  &__photo {
    margin-right: 20px;
    border-radius: 50%;
    width: 113px;
    height: 113px;
    object-fit: cover;
    object-position: top;
  }

  &-desc {
    margin-top: 10px;
    max-width: 411px;

    &__name {
      font-weight: bold;
    }

    &__item {
      font-size: 0.875rem;
      line-height: 120%;
      margin-bottom: 2px;
    }

    &__birthdate {
      font-style: italic;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 120%;
      margin-bottom: 12px;

      @media (max-width: 575px) {
        font-size: 13px;
      }
    }

    &__referral,
    &__education {
      font-weight: bold;

      p {
        font-weight: normal;
      }

      @media (max-width: 575px) {
        font-size: 0.8125rem;
      }
    }

    &__education {
      margin-top: 5px;
    }
  }
}
