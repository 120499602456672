.header {
  padding: 15px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__user {
    padding-right: 41px;
  }

  .menu {
    &__link {
      display: flex;
      align-items: center;
    }

    &__item {
      background: url('../../img/menu.png') no-repeat;
      background-size: contain;
      width: 22px;
      height: 12px;
    }

    &__headline {
      padding-left: 8px;
      color: $header-text-color;
    }
  }
}

.user {
  &__link {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__arrow {
    margin-right: 14.2px;
    background: url('../../img/arrow-down.svg') no-repeat;
    background-size: contain;
    width: 11.6px;
    height: 6.8px;
  }

  &__name {
    margin-right: 20px;
    color: $header-text-color;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $icon-bg-color;
    width: 63px;
    height: 63px;
    text-align: center;
    border-radius: 100%;
    color: $btn-text-color;
    font-size: 24px;
  }

  &__icon_small {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }
}
