.test {
  &__questions {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 7px;
  }

  &__btn {
    padding-top: 12px;
  }
}

.test__answers {
  &-item {
    display: flex;
    flex-direction: row;
  }
}

.test__label {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
  width: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.test__label:hover {
  cursor: default;
}

.slider {
  position: relative;
  overflow: hidden;
}
.slider__wrapper {
  display: flex;
  transition: transform 0.6s ease; /* 0.6 длительность смены слайда в секундах */
}
.slider__item {
  flex: 0 0 100%; /* определяет количество активных слайдов (в данном случае 2 */
  max-width: 100%; /* определяет количество активных слайдов (в данном случае 2 */
}
