.checkbox {
  --active: #1dc255;
  --border-hover: #218838;
  --focus: 2px rgba(72, 180, 97, 0.5);
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  width: 21px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  border: 1px solid var(--bc, #b2b2b2);
  background: var(--b, #fff);
  transition: all 0.4s ease;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 7px;
    top: 4px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    width: 5px;
    height: 9px;
    transform: rotate(var(--rotate, 20deg));
    transition: all 0.4s ease;
    opacity: var(--o, 0);
  }
  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --o: 1;
    --rotate: 43deg;
  }
  &:hover {
    &:not(:checked) {
      --bc: var(--border-hover);
    }
  }
  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  & + label {
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 10px;
  }
}
