.question {
  &__item {
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__block-text {
    padding-top: 20px;
    display: inline-block;

    .form__link {
      &_delete {
        font-size: 16px;
      }

      &_edit {
        margin-right: 20px;
      }
    }
  }

  &__img {
    object-fit: contain;
  }

  &__paragraph {
    line-height: 120%;
  }
}

.edit-questions {
  margin-top: 40px;
}
