.settings {
  &__title {
    margin-bottom: 25px;
  }

  &__subtitle {
    font-weight: normal;
  }

  &__block {
    margin-top: 50px;
    max-width: 1160px;
    width: 100%;
  }

  &__wrapper {
    margin-bottom: 40px;
  }
}

.access-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 84px;

  &__item {
    display: flex;
    align-items: center;
    width: 270px;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &__action {
    margin-right: 12px;
  }

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .custom-checkbox {
      display: block;
    }
    .custom-checkbox__control {
      appearance: none;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      cursor: pointer;
      width: 20px;
      height: 20px;
      border: 1.2px solid $gray-light;
      border-radius: 50%;
      transition: all 0.3s ease;
      &:after {
        content: '';
        background-image: url('../../images/checked-icon.svg');
        background-size: cover;
        background-position: center;
        background-size: 12px;
        background-repeat: no-repeat;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.3s ease;
      }

      &:checked {
        background-color: $color-main;
        border-color: $color-main;
        &::after {
          opacity: 1;
        }
      }

      &:hover {
        border-color: $color-main;
      }

      &:disabled {
        background-color: $gray-dark;
        border-color: $gray-dark;
      }
    }

    .custom-checkbox__name {
      margin-left: 20px;
      vertical-align: middle;
    }
  }
}
