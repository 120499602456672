*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-width: 320px;
  color: $black;
  font-size: 0.875rem;

  font-family: 'SF Pro Display', sans-serif;
  @media (max-width: 575px) {
    font-size: 0.8125rem;
  }
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

.container {
  width: 100%;
  max-width: 1190px;
  padding: 0 15px;
  margin: 0 auto;

  font-size: 0.875rem;

  font-family: 'SF Pro Display', sans-serif;
  @media (max-width: 575px) {
    font-size: 0.8125rem;
  }

  h1 {
    font-weight: 800;
    font-size: 3.4375rem;

    @media (max-width: 575px) {
      font-size: 1.875rem;
    }
  }

  h2 {
    font-weight: 800;
    font-size: 2rem;
    @media (max-width: 575px) {
      font-size: 1.75rem;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 1.25rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p,
  a,
  span {
    line-height: 120%;
  }
}

.pagination {
  margin-top: 10px;
  margin-bottom: 30px;

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 120%;
    color: $gray;
  }

  span {
    color: $gray;
    margin: 0 5px;
  }
}

.bold {
  font-weight: bold;
}

// PREFERENCE STYLES FOR VISUALLY IMPAIRED USERS
.visibility {
  &__grey-contrast {
    filter: grayscale(100%);
  }

  &__color-invert {
    filter: invert(100%);
    background-color: $black;
  }

  &__large-cursor--black {
    cursor: url(../img/large-cursor-black.png) 15 0, move;
    a,
    button {
      cursor: url(../img/large-cursor-pointer-black.png) 20 1, move;
    }
    div[role='button'] {
      cursor: url(../img/large-cursor-pointer-black.png) 20 1, move;
    }
  }
  &__large-cursor--white {
    cursor: url(../img/large-cursor-white.png) 15 0, move;
    a,
    button {
      cursor: url(../img/large-cursor-pointer-white.png) 20 1, move;
    }
    div[role='button'] {
      cursor: url(../img/large-cursor-pointer-white.png) 20 1, move;
    }
  }

  &__text-zoom-1 {
    font-size: 20px;
  }
  &__text-zoom-2 {
    font-size: 24px;
  }
  &__text-zoom-3 {
    font-size: 28px;
  }
}
