$white: #fff;
$black: #000;
$gray-lighten: #d9d6d6;
$gray-light: #c4c4c4;
$gray: #b2b2b2;
$gray-dark: #b3b3b3;
$gray-darken: #2b2f37;
$detail-color: #2e78e7;
$color-main: #f8742f;
$color-danger: #ee2424;
$color-success: #91dc56;

$line-color: $gray-lighten;
$accent-text-color: $color-main;
$main-text-color: $black;
$text-color: $gray;
$header-text-color: $gray-darken;
$nav-text-color: $gray-dark;
$btn-bg-color: $color-main;
$btn-text-color: $white;
$icon-bg-color: $gray-light;
$radio-btn: $color-success;
$btn-bg-delete-color: $color-danger;
