.nav {
  position: relative;
  padding-top: 14px;
  margin-bottom: 40px;

  .hr-line {
    border: none;
    border-top: 1px solid $line-color;
    position: relative;
    top: -1px;
    z-index: -1;
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__item {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    color: $nav-text-color;
    display: block;
    padding: 10px 0;
    background: transparent;

    &_active {
      border-bottom: 2px solid $accent-text-color;
      color: $main-text-color;
    }
  }
  &_mb {
    padding-bottom: 14px;
  }
}
.page {
  &__head {
    max-width: 890px;
    margin-bottom: 30px;
  }

  &__description {
    line-height: normal;
  }

  &__action {
    display: flex;
    margin-bottom: 40px;

    &-info {
      font-size: 13px;
      color: #b2b2b2;
      max-width: 240px;
      line-height: normal;
    }

    &-btn {
      margin-right: 32px;
      min-width: 158px;
    }
  }
}

.status {
  color: #1dc255;

  &__icon {
    background-color: #1dc255;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    padding: 2px;
    margin-right: 6px;
  }

  &__icon,
  &__title {
    vertical-align: middle;
  }

  &__date {
    font-size: 13px;
    color: #b2b2b2;
  }

  &__group_mr {
    margin-right: 46px;
  }
}

.article {
  max-width: 830px;

  &__btn {
    padding-top: 32px;
    color: #fff;
  }

  p {
    line-height: 1.5;
    margin-bottom: 16px;
  }

  &__content {
    padding-top: 32px;
  }

  &__test {
    margin-top: 40px;
  }
}

.headline-block {
  max-width: 865px;
  display: flex;
  justify-content: space-between;
}
