.btn {
  position: relative;
  margin-top: 40px;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  max-width: 865px;
  cursor: pointer;
  transition: all 0.2s ease;
  overflow: hidden;

  &__save {
    color: $btn-text-color;
    padding: 13px 35px;
    background: $btn-bg-color;

    &:hover {
      background-color: #d5652a;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem var(--btn-focus, rgba(108, 117, 125, 0.5));
    }
  }

  &__outline {
    background: transparent;
    padding: 13px 35px;
    border: 1px solid $btn-bg-color;
    color: $accent-text-color;
    vertical-align: middle;
  }

  &__settings {
    position: relative;
    &:after {
      content: '';
      background-image: url('../../images/settings.svg');
      width: 18px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 18px;
    }
  }

  &__delete {
    color: $btn-text-color;
    padding: 13px 35px;
    background: $btn-bg-delete-color;
  }

  &__group {
    .btn__item + .btn__item {
      margin-left: 10px;
    }
  }
}

.btn-primary {
  background: transparent;
}

.btn-secondary {
  padding: 13px 35px;
  background-color: var(--btn, #b2b2b2);
  color: #fff;
  line-height: inherit;
  transition: all 0.4s ease;
}

.btn-secondary:hover {
  background-color: var(--btn-hover, #75787a);
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem var(--btn-focus, rgba(108, 117, 125, 0.5));
}

.btn-secondary_blue {
  background-color: #2e78e7;
  min-width: 185px;
  min-height: 45px;
  color: #fff;
  padding: 13px 35px;
  &:hover {
    background-color: #0069d9;
  }
  &:focus {
    background-color: rgba(38, 143, 255, 0.5);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.btn-card__custom {
  position: absolute;
  right: 10px;
  top: 15px;
}
