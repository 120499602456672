*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  line-height: 120%;
  background-color: $white;
  overflow-x: hidden;
}

button {
  cursor: pointer;
  background-color: transparent;
}

button,
input {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1190px;
  padding: 0 15px;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: 'SF Pro Display', serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  color: $main-text-color;
  margin: 0;
  line-height: 80%;
}

.headline {
  line-height: 120%;
  font-size: 32px;
  font-weight: 600;
}

.headline_description {
  font-size: 16px;
  color: $text-color;
  font-style: italic;
  font-weight: normal;
  padding: 8px 0 10px;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: $detail-color;
}

.error {
  margin-top: 5px;
  display: block;
  color: red;
}

h1.title,
h2.title-sub {
  font-weight: 600;
  line-height: normal;
}

h1.title {
  font-size: 32px;
  margin-bottom: 15px;
}

h1.title_mb-large {
  margin-bottom: 30px;
}

h2.title-sub {
  font-size: 24px;
  margin-bottom: 13px;
}

.pointer {
  cursor: pointer;
}
