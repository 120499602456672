.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  &__item {
    padding: 20px;
    width: 275px;
    height: auto;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $line-color;
    position: relative;

    &_create {
      border: 1px dashed $btn-bg-color;
      justify-content: center;
      align-items: center;
    }
  }

  &__headline {
    color: $main-text-color;
    font-weight: bold;
    line-height: 120%;

    &_create {
      color: $accent-text-color;
    }
  }

  &__paragraph {
    color: $text-color;
    font-style: italic;
    font-size: 13px;
    line-height: 120%;
    margin-top: 5px;
    margin-bottom: 10px;
    flex: 1;
  }

  &__link {
    color: $accent-text-color;
  }

  &__link_btn {
    background: transparent;
    color: #ee2424;
    text-align: left;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }
}

.content-inner {
  margin-top: 40px;
}
