.overlay {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba($color: #2b2f37, $alpha: 0.5);
  background: url('../../img/login-bg.svg') right bottom no-repeat,
    linear-gradient(135deg, #ffe985 -50%, #fa742b 150%);
  background-size: contain;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  .login {
    padding: 60px 40px;
    max-width: 657px;
    width: 100%;
    background-color: $white;
    z-index: 1000;

    &__inner {
      max-width: 310px;
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }

    &__title {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 20px;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      color: #9e9e9e;
      margin-bottom: 30px;
      font-style: italic;
    }

    &__textfield {
      margin-top: 36px;
    }

    form {
      display: flex;
      flex-direction: column;

      .form__button {
        background: $color-main;
        padding: 13px 35px;
        width: 100%;
        font-size: 16px;
        color: $white;
        margin-top: 36px;
      }
    }
  }

  .personal-data {
    &__inner {
      max-width: 420px;

      .privacy {
        text-align: left;
        font-size: 14px;
        line-height: 26px;

        p,
        a {
          font-size: 14px;
          margin-bottom: 0;
          line-height: 26px;
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }
}
