.courses-navigator {
  .chapters-list,
  .lessons-list {
    a {
      font-size: 16px;
      color: $white;
    }
  }

  .chapters-list {
    height: 100%;
    max-height: calc(100vh - 175px);
    overflow-y: auto;

    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 362px;
    }

    li {
      margin-bottom: 20px;

      a {
        font-weight: bold;
        margin-right: 15px;
        max-width: 100%;
      }
    }

    .lessons-list {
      li {
        margin-top: 16px;
        margin-bottom: 0;
        margin-left: 25px;
        a {
          font-weight: normal;
          max-width: 315px;
        }
      }
    }
  }
}
