.contacts {
  h2 {
    margin-bottom: 30px;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}
